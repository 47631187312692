 @font-face { font-family: Phorssa; src: url('Phorssa.ttf'); } 

:root {
  --color-black: #000000;
  --color-gray: #828282;
  --color-dark: #444444;
  --color-white: #f2f2f2;
  --color-yellow: #ffff00;

  --saved-words-width: 400px;
  --time-to-meaning: 300ms;

}

body {
    margin: 5% auto;
    background: #f2f2f2;
    color: var(--color-dark);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.8;
    max-width: 73%;
    overflow-x: scroll;
}

a {
    border-bottom: 1px solid var(--color-dark);
    color:  var(--color-dark);
    text-decoration: none;
}

.hr {
  height: 1px;
  color: #bfbfbf;
  background-color: #bfbfbf;
  border: none;
}

.flex-container {
    display: flex;
    flex-wrap: nowrap;
}

.horizontal-fit {
    justify-content: space-between;
}

input {
  flex: 2;
}

.hover .hover--on,
.hover:hover .hover--off {
  display: none;
}

.hover:hover .hover--on {
  display: inline;
}

/* Game Word */ 
.already-saved {
  background-color: var(--color-yellow);
}

.not-saved-fresh {
  animation-duration: 1.5s;
  animation-name: remove-highlight;
  animation-iteration-count: 1;
  animation-fill-mode: initial
}

.not-saved {
  animation-duration: 0.3s;
  animation-name: remove-highlight;
  animation-iteration-count: 1;
  animation-fill-mode: initial
}

.not-saved-add-highlight {
  animation-duration: calc(var(--time-to-meaning) + 300ms);
  animation-name: add-highlight;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  background-color: var(--color-yellow);
}

.meaning-preview,
.meaning-preview::after {
    animation-delay: var(--time-to-meaning);
    animation-iteration-count: var(--iterations, 1);
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
}

.meaning-preview {
    --animation-delay: var(--delay, 0);
    --animation-duration: var(--duration, 800ms);
    --animation-iterations: var(--iterations, 1);
    position: relative;
    font-size: 32px;
    animation-name: clip-text;
    color:var(--color-gray);
    font-style: italic;
    white-space: nowrap;
    max-width: 0%;
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
}

@keyframes clip-text {
    0% {
      max-width: 0%;
    }
    100% {
      max-width: 100%;
    }
}

.game-text {
  margin-top: 40px;
}

.game-word {
  cursor: help;
  pointer-events: all;
  animation-duration: 1s;
  animation-name: disable-pointer;
  animation-iteration-count: 1;
  user-select: none;
  /* font-family: phorssa; */
  /* font-size: 80px; */
}

.tooltip-button {
  cursor: pointer;
}

 /* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  font-size: 16px;
  width: 150px;
  background-color: var(--color-black);
  color: var(--color-white);
  text-align: center;
  text-decoration:none;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 90%;
  left: 50%;
  margin-left: -75px;

  /* Fade in tooltip */
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--color-black) transparent transparent transparent;
}

.btn-primary {
  padding: 12px 12px;
  border-radius: 4px;
  background-color: var(--color-black);
  text-align: center;
  color: var(--color-white);
  font-size: 16px;
  font-weight: 700;
  border:0;
}

.btn-primary:hover {
  padding: 12px 12px;
  border-radius: 4px;
  background-color: var(--color-black);
  text-align: center;
  color: var(--color-white);
  font-size: 16px;
  font-weight: 700;
  border:0;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.3);
}

.btn-primary:active {
  padding: 12px 12px;
  border-radius: 4px;
  background-color: var(--color-black);
  border:0;
}

.meaning-hover {
  background-color: var(--color-yellow);
}

@keyframes add-highlight {
  0% {
    background-color: #00000000;
  }
  100% {
    background-color: var(--color-yellow);
  }
}

@keyframes disable-pointer {
  0% {
    pointer-events: none
  }
  100% {
    pointer-events: none
  }
}

@keyframes remove-highlight {
  0% {
    background-color: var(--color-yellow);
  }
  100% {
    background-color: #00000000;
  }
}

.illustration-enter {
  opacity: 0.01;
}

.illustration-enter.illustration-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-in;
}

.illustration-exit {
  opacity: 1;
}

.illustration-exit.illustration-exit-active {
  opacity: 0.01;
  transition: opacity 400ms ease-in;
}
.illustration-enter {
  opacity: 0.01;
}

.start-over {
  top: 30px;
  padding: 12px 12px;
  cursor: pointer;
}

.saved-words-container { 
  position: absolute;
  top:0;
  right: 0;
  display: inline;
}

.saved-words-list {
  width: var(--saved-words-width);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  height: 100%; 
  position: fixed;
  z-index: 1; 
  top: 0; 
  right: 0;
  overflow-x: hidden; 
  overflow-y: scroll;
  padding-top: 60px; 
  padding-left: 24px;
  background-color: #fff;
}

.saved-words-enter {
  width: 0; 
  height: 100%; 
  position: fixed;
  z-index: 1; 
  top: 0; 
  right: 0;
  background-color: #fff;
  overflow-x: hidden; 
  overflow-y: scroll;
  padding-top: 60px; 
  padding-left: 24px;
}

.saved-words-enter.saved-words-enter-active {
  width: var(--saved-words-width);
  transition: width 500ms ease-in;
}

.saved-words-exit {
  width: var(--saved-words-width);
}

.saved-words-exit.saved-words-exit-active {
  width: 0;
  transition: width 500ms ease-in;
}

.saved-words-open-btn {
  position: absolute;
  top: 30px;
  right: 80px;
  width: 200px;
  padding: 12px 12px;
  border-radius: 4px;
  background-color: #000;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border:0;
  cursor: pointer;
}

.saved-words-open-btn:hover {
  Float: right;
  padding: 12px 12px;
  border-radius: 4px;
  background-color: #444;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border:0;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}

.saved-words-close-btn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

image, rect, circle {
  mix-blend-mode: multiply !important;
  background-blend-mode: multiply !important;
}

.btn-secondary {
  padding: 12px 12px;
  border-radius: 4px;
  background-color: #ffffff;
  text-align: center;
  color: #333333;
  font-size: 16px;
  font-weight: 700;
  border: 1px solid black;
  border-color: #bfbfbf;
  cursor: pointer;
}

.btn-secondary:hover {
  padding: 12px 12px;
  border-radius: 4px;
  background-color: #ffffff;
  text-align: center;
  color: #333333;
  font-size: 16px;
  font-weight: 700;
  border: 1px solid black;
  border-color: #bfbfbf;
  cursor: pointer;
}

.saved-words-title {
    font-weight: bold;
    font-size: 24px;
    color: #444444;
    text-decoration: none;
}
.saved-words-word {
    display: block;
    margin-block-start: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    font-size: 16px;
    color: #444444;
    text-decoration: none;
    background-color: #ffff00;
}

.saved-words-description {
    display: block;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    font-size: 16px;
    font-style: italic;
    color: #828282;
    text-decoration: none;
}

/* DictionaryView */
.dictionary-element {
    display: flex;
    flex-wrap: nowrap;
    margin-left: 0px;
    flex-direction: row;
    justify-content: space-between;
}

.dictionary-element-edit {
  flex-grow: 1
}

.dictionary-block {
  width: 100%;
  padding: 20px;
}

.progress-meanings[value]::-webkit-progress-value {
  background-color: var(--color-yellow)
}

.progress-meanings {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;
  margin: 0;
  border-style: none;
  z-index: 10;
}

.progress-meanings > span {
  display: block;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: var(--color-yellow);
  transition: width 500ms;
}

.progress-written {
  display: none;
  user-select: none;
  font-size: 12px;
  font-weight: bold;
  vertical-align: middle;
}

.progress-meanings:hover .progress-written {
  display: inline;
}

/* .illustration {
  position: fixed;
  height: 100%;
  width: 100%;
  height: 600px;
  left: 0;
  bottom: 0;
  border-style: solid;
  border-width: 20px;
  z-index: -10;
} */

.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
}

.modal {
  position: fixed; 
  padding-top: 50px;
  left: 0; 
  top: 0;
  width: 100%;
  height: 100%; 
  z-index: 10;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  position: relative; 
  background-color: white;
  padding: 20px; 
  margin: auto; 
  width: 75%;  
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

.close-btn {
  float: right; 
  color: lightgray; 
  font-size: 24px;  
  font-weight: bold;
}

.close-btn:hover {
  color: darkgray;
}

@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}